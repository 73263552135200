import {Link} from 'gatsby';
import React from 'react';

const Header = () => (
    <>
        <header style={{backgroundColor: '#f5f5fb'}}>
            <div className='rcards'>
                <div className='rcard'>
                    <Link
                        to={'/'}
                        style={{color: 'transparent'}}
                    >
                        <h1>React Native Consultant</h1>
                    </Link>
                </div>

                <div className='rcard'>
                    <div className='flex-container'>
                        <Link
                            to={'/blog'}
                            style={{color: 'transparent'}}
                        >
                            <button>{'Blog'}</button>
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    </>
);

export default Header;
